import axios from 'axios';

const sign = require('jwt-encode');
const secret = 'hbGciOiJxNTE2MjM5MIUzI1NiIsInR5cCI6ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaNTY3fyWF0IjoDIew56y';
const data = {
  name: 'Domy Trio',
};
const jwt = sign(data, secret); 

const axiosInstance = axios.create({
  // withCredentials: true,
  baseURL: 'https://www.domytrio.cz/admin/public/',
  headers: {
    'Content-Type': 'application/json',
    "Authorization" : `Bearer ` + jwt
  }
});

export default axiosInstance;