/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';

import React, {useReducer, useEffect, useState} from "react"
import { useStaticQuery, graphql } from "gatsby"   
import { shopReducer, pageReducer, SET_MENU, SET_ITEMS } from "./src/context/reducers";
import axiosInstance from "./src/client/index.js";
import SimpleReactLightbox from 'simple-react-lightbox'
import PageContext from "./src/context/PageContext";

const App = (props) => {
    const data = useStaticQuery(graphql`
    query Pages {
      scomos {
        pageFindAll(menu:"main") {
          id
          parent 
          menuName
          menu
          url
          lft
        }
      }
    }
  `)
  
    
    const [ menu, dispatchMenu ] = useReducer(pageReducer, { menu: [] }); 
 
   
    useEffect(() => {   
      let items = []; 
      let mainId = 0;
   
      function menuItems() { 
          data.scomos.pageFindAll.forEach(menuItem => {
            if(menuItem.parent==='0') { 
              mainId = menuItem.id
            }
            items[menuItem.id] = menuItem
          })
    
          let newItems = [...items]
          items.forEach((menuItem) => {
            if(items[menuItem.parent]) {
              if(!items[menuItem.parent]['children']) {
                newItems[menuItem.parent]['children'] = [];
              }
              newItems[menuItem.parent]['children'][menuItem.lft] = menuItem
            }
          })
   
          let sorted = []
          let i = 0;
          newItems[mainId]['children'].forEach((newMenuItem) => {
            sorted[newMenuItem.lft] = newMenuItem
            i++
          }); 
    
          return sorted;
        }
   
       let newMenu = menuItems();
       dispatchMenu({ type: SET_MENU, data:newMenu }); 
        // eslint-disable-next-line
      }, []); 
    

    return (
    <PageContext.Provider
    value={{ 
        menu: menu,
    }}
    >
        {props.children}
    </PageContext.Provider>

    )
}

export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>
    <App>
      {element}
    </App>
  </SimpleReactLightbox>
)

